import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const Pagination = ({ currentPage, totalPages, onPageChange, pageInput, setPageInput }) => {
    let pageNumbers = [];

    const [pageNumber, setPageNumber] = useState(pageInput);

    const handlePageChange = (e) => {
      if(e.key === 'Enter') {
        if (pageInput >= 1 && pageInput <= totalPages){
          onPageChange(pageInput);
        }
      }
    }
  
    if (totalPages <= 10) {
      // show all pages if there are 10 or fewer
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // show up to 10 pages and shrink the rest to dots
      const half = Math.floor(7 / 2);
      const start = Math.max(1, currentPage - half);
      const end = Math.min(totalPages, currentPage + half);
  
      if (start > 1) {
        pageNumbers.push(1, '...');
      }
  
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
  
      if (end < totalPages) {
        pageNumbers.push('...', totalPages);
      }
    }

  return (
    <nav className="px-4 py-3 flex items-center justify-between sm:px-6">
      <div className="hidden sm:block">
        <p className="text-sm text-gray-300">
          Showing page <span className="font-medium">{currentPage}</span> of <span className="font-medium">{totalPages}</span> pages

          {/* o <span className="font-medium">{(currentPage*20)}</span> */}
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          {/* {pageNumbers.map(number => (
            <button
              key={number}
              onClick={() => onPageChange(number)}
              className={`${
                currentPage === number
                  ? 'bg-blue-500 text-white'
                  : 'hover:bg-gray-100'
              } relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium`}
            >
              {number}
            </button>
          ))} */}
          {currentPage > 1 && <span className="cursor-pointer relative inline-flex items-center px-4 py-2 h-8 border border-gray-300 text-sm font-medium text-gray-700"
            onClick={() => {
              setPageInput(currentPage - 1);
              onPageChange(currentPage - 1);
            }}>
            <IoIosArrowBack />
          </span>}
          <input min={1} max={totalPages} value={pageInput} className="text-gray-300 bg-transparent px-2 py-2 w-16 h-8 border border-gray-300 text-sm font-bold text-center"
            onKeyDown={handlePageChange} onChange={(e) => {setPageInput(e.target.value)}} />
          {currentPage < totalPages && <span className="cursor-pointer relative inline-flex items-center px-4 py-2 h-8 border border-gray-300 text-sm font-medium text-gray-700"
            onClick={() => {
              setPageInput(currentPage + 1)
              onPageChange(currentPage + 1);
            }}>
            <IoIosArrowForward />
          </span>}
        </nav>
      </div>
    </nav>
  );
};

export default Pagination;
